import { render, staticRenderFns } from "./titleCard.vue?vue&type=template&id=34d21910&scoped=true&"
import script from "./titleCard.vue?vue&type=script&lang=js&"
export * from "./titleCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d21910",
  null
  
)

export default component.exports