<template>
  <div>
    <b-card title="Ürün Bilgisi">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <price-text :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <offer-price :data-key="dataKey" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import OfferPrice from '@/views/BatteryOffers/elements/lines/offerPrice.vue'
import PriceText from '@/views/BatteryOffers/elements/lines/priceText.vue'

export default {
  name: 'OfferProduct',
  components: {
    PriceText,
    OfferPrice,
    BCard,
    BRow,
    BCol,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    lineData() {
      const LineData = this.$store.getters['batteryOffers/getOffer']
      return LineData.lines
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('batteryOffers/offerView', this.$route.params.id)
    },
  },
}
</script>
