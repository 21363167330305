<template>
  <b-form-group
    label="Ürün Açıklaması"
    label-for="price_text"
  >
    <validation-provider
      #default="{ errors }"
      name="Ürün Açıklaması"
      rules="required"
    >
      <b-form-input
        id="price_text"
        v-model="lineData[dataKey].title"
        placeholder="Ürün Açıklaması"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'PriceText',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['batteryOffers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['batteryOffers/getOffer']
      return lineData.lines
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
